// available statuses
export const statuses = {
  INTENT: 0,
  CANCELLED: 1,
  CONFIRMED: 40,
  MAKING: 30,
  READY: 20,
  PICKED: 10,
}

export const statusNames = {
  [statuses.INTENT]: 'INTENT',
  [statuses.CANCELLED]: 'CANCELLED',
  [statuses.CONFIRMED]: 'CONFIRMED',
  [statuses.MAKING]: 'MAKING',
  [statuses.READY]: 'READY',
  [statuses.PICKED]: 'PICKED'
}

// status colors 
export const statusColors = {
  [statuses.INTENT]: 'black',
  [statuses.CANCELLED]: 'grey.500',
  [statuses.CONFIRMED]: 'red',
  [statuses.MAKING]: 'blue',
  [statuses.READY]: 'green',
  [statuses.PICKED]: 'grey.800'
}

// convenient fns
export const cancellAllowed = [statuses.INTENT, statuses.CONFIRMED, statuses.MAKING, statuses.READY]
export const canCancell = status => cancellAllowed.includes(status)

// where goes forward
export const nextStatus = {
  [statuses.CONFIRMED]: statuses.MAKING,
  [statuses.MAKING]: statuses.READY,
  [statuses.READY]: statuses.PICKED
}

// where goes backward
export const backStatus = {
  [statuses.MAKING]: statuses.CONFIRMED,
  [statuses.READY]: statuses.MAKING,
  [statuses.PICKED]: statuses.READY
}

export const canForward = status => Object.keys(nextStatus).includes(status)
export const canBackward = status => Object.keys(backStatus).includes(status)
